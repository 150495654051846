

















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import coreApiWorkerClient from "@/services/apis/coreApiWorkerClient";
import DataContainer from "@vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      country: null,
      tableOptions: null,
      userManager,
    };
  },
  methods: {
    labelValues(item) {
      const value = item.propertyValues;
      if (!value) {
        return [];
      }
      const labelValues = [];
      for (const k in value) {
        const v = value[k];
        const labelValue: any = {
          key: k,
          value: v,
        };

        if (item.productGroup && item.productGroup.properties) {
          labelValue.property = item.productGroup.properties.find((p) => p.key === k);
        }
        labelValues.push(labelValue);
      }
      return labelValues;
    },
    makeTableOptions(country) {
      const options = {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          name: "Quản lý sản phẩm",
          urlEnabled: true,
          search: {},
          filters: {
            filters: {
              _id: {
                attrs: {
                  label: "ID",
                },
                rules: {
                  equal_to_id: {},
                },
              },
            },
          },
          displayFields: {
            _id: {
              text: "ID",
              hidden: true,
            },
            sku: {
              text: "SKU",
              sortable: true,
              width: "200px",
            },
            name: {
              text: "Tên",
              sortable: true,
            },
            propertyValues: {
              text: "Thuộc tính",
              width: "200px",
            },
            priceInherited: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              text: "Lấy giá từ NSP",
              options: {
                boolean: true,
              },
            },
            count: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              text: "SL thực trong kho",
              options: {
                subProp: "totalCountStatus.count",
                sortBy: "totalCountStatus.count",
                filter: "number2",
              },
            },
            expectedCount: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              text: "SL trong kho (Dự kiến)",
              options: {
                subProp: "totalCountStatus.expectedCount",
                sortBy: "totalCountStatus.expectedCount",
                filter: "number2",
              },
            },
            expectedImportCount: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              text: "SL dự kiến về",
              options: {
                subProp: "totalCountStatus.expectedImportCount",
                sortBy: "totalCountStatus.expectedImportCount",
                filter: "number2",
              },
            },
            returnCount: userManager.checkRole(["kt"]) && {
              text: "SL return",
              options: {
                subProp: "totalCountStatus.returnCount",
                sortBy: "totalCountStatus.returnCount",
                filter: "number2",
              },
            },
            mktCount: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              text: "SL Marketing",
              options: {
                preTransform(_, item) {
                  return item.overriddenMktCount || (item.totalCountStatus && (item.totalCountStatus.expectedCount || 0) + (item.totalCountStatus.expectedImportCount || 0));
                },
                filter: "number2",
              },
            },
            latestBaseCost: userManager.checkRole(["kt"]) && {
              text: "Basecost lô gần nhất",
              hidden: true,
              options: {
                filter: "currency",
              },
            },
            avgBaseCost: userManager.checkRole(["kt"]) && {
              text: "Basecost TB tât cả lô",
              hidden: true,
              options: {
                filter: "currency",
              },
            },
            temporaryBaseCost: userManager.checkRole(["kt"]) && {
              text: "Basecost tạm thời",
              hidden: true,
              options: {
                filter: "currency",
              },
            },
            baseCostCalculateInitCount: userManager.checkRole(["kt"]) && {
              text: "SL tồn kho khởi tạo",
              hidden: true,
              options: {
                filter: "number3",
              },
            },
            baseCostCalculateInitValue: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              text: "Giá trị tồn kho khởi tạo",
              hidden: true,
              options: {
                filter: "currency",
              },
            },
            purchaseAvgPerDay: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              text: "Số đơn vị phát sinh TB/ngày",
              sortable: true,
              options: {
                filter: "number2",
              },
            },
            purchaseEstimatedRemainingDays: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              text: "Số ngày tồn kho còn đủ",
              sortable: true,
              options: {
                transform(value, item) {
                  if (!item.purchaseAvgPerDay) {
                    return "";
                  }
                  return value;
                },
                filter: "number2",
              },
            },
            purchaseAvgPerDayThreshold: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              text: "Số ngày tiêu chuẩn",
              sortable: true,
              filter: "number2",
            },
            localPrice: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl"]) && {
              text: "Giá bán nội địa",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${country.currency}`;
                },
              },
            },
            localShippingCost: userManager.checkRole(["kt", "vdl", "vd"]) && {
              text: "Chi phí ship nội địa",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${country.currency}`;
                },
              },
            },
            realLocalShippingCost: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl"]) && {
              text: "Chi phí ship nội địa thực",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${country.currency}`;
                },
              },
            },
            expectedRevenue: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl"]) && {
              text: "Doanh thu MKT",
              options: {
                preTransform(_, item) {
                  let value: any = (item.localPrice - item.localShippingCost) * country.exchangeRate;
                  if (isNaN(value)) {
                    value = "";
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            description: {
              text: "Mô tả",
            },
            updatedTime: {
              text: "Ngày cập nhật",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            import: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "products",
                                  "import",
                                  {
                                    countryId: country._id,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("products", `@/export`, findAllOptions);
                },
              },
            },
            calculateCountStatuses: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Tính lại stock",
                icon: "mdi-calculator-variant-outline",
              },
              on: {
                async xClick({ self }) {
                  const dataTable = self.context();
                  self.loading.value = true;
                  await dataTable.execute(
                    async () => {
                      return await coreApiClient.call("products", "calculateCountStatuses", {
                        countryId: country._id,
                      });
                    },
                    undefined,
                    {
                      disableLoading: true,
                      willRefresh: true,
                    }
                  );
                  self.loading.value = false;
                },
              },
            },
            calculateBaseCost: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Tính base cost",
                icon: "mdi-calculator",
              },
              target: {
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Tính base cost",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  startTime: {
                                    type: "XDateTimePicker",
                                    attrs: {
                                      label: "Ngày bắt đầu",
                                    },
                                  },
                                  endTime: {
                                    type: "XDateTimePicker",
                                    attrs: {
                                      label: "Ngày kết thúc",
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Submit",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiWorkerClient.call(
                                  "orderBaseCosts2",
                                  "calculate",
                                  {
                                    startTime: data.startTime,
                                    endTime: data.endTime,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "qlk"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "sku",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["kt"]) && {},
              updatePrice: userManager.checkRole(["kt"]) && {
                attrs: {},
                content: {
                  icon: "mdi-currency-usd",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Cập nhật giá",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật giá",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.sku || item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            newLocalPrice: item.localPrice,
                            newLocalShippingCost: item.localShippingCost,
                          });
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    newLocalPrice: {
                                      type: "number",
                                      attrs: {
                                        label: "Giá bán nội địa mới",
                                        required: true,
                                        "prepend-icon": "mdi-currency-usd",
                                        suffix: country.currency,
                                        autofocus: true,
                                      },
                                    },
                                    newLocalShippingCost: {
                                      type: "number",
                                      attrs: {
                                        label: "Chi phí ship nội địa mới",
                                        required: true,
                                        "prepend-icon": "mdi-currency-usd",
                                        suffix: country.currency,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "products",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        localPrice: data.newLocalPrice,
                                        localShippingCost: data.newLocalShippingCost,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "countryId",
              operator: "equal_to",
              value: this.context().$route.params.countryId,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("products", "findAll", options);
              return [items, count];
            },
            async delete(item) {
              return await coreApiClient.call("products", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              sku: {
                attrs: {
                  label: "SKU",
                  required: true,
                },
              },
              name: {
                attrs: {
                  label: "Tên",
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              priceInherited: {
                type: "boolean",
                attrs: {
                  label: "Lấy giá từ NSP",
                },
              },
            },
            delete: userManager.checkRole(["kt"]) && {
              confirmDisplayField: "sku",
            },
          },
        },
      };
      return options;
    },
  },
  async created() {
    this.country = await coreApiClient.call("countries", "findById", {
      id: this.$route.params.countryId,
    });
    this.tableOptions = this.makeTableOptions(this.country);
  },
});
